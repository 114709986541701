import React from "react";
import { useTranslation } from "react-i18next";
import {
  IconsWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/pages/PagePartners/components/InstitutionsComponent/styledComponent";
import { institutionsList } from "src/core/lists/institutionsList";

export const InstitutionsComponent = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t("partner_insitutions")}</Title>
        <SubTitle>{t("partner_insitutions_desc")}</SubTitle>
      </TitleWrapper>
      <IconsWrapper>
        {institutionsList.map((icon, i) => (
          <img key={i} src={icon.icon} alt={icon.icon} />
        ))}
      </IconsWrapper>
    </Wrapper>
  );
};
