import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background: #f8f8f8;
  border-radius: 10px;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: -50px;
  margin-right: 550px;

  img {
    width: 100%;
    max-width: 270px;
  }

  @media (max-width: 1600px) {
    margin-right: 650px;
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 770px;
  margin-left: 33%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 28.04px;
  text-align: center;
  color: #062762;
  max-width: 680px;
`;

export const Description = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #000000;
`;

export const SubDescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const SubDescription = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #000000;
`;
