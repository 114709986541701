import React from "react";
import { Box, Button } from "@mui/material";
import mainPhoto from "../../../assets/img/global/truss_gold_blue.jpg";
import { FooterPayment } from "src/components/molecules/FooterPayment";
import { useTranslation } from "react-i18next";
import done from "../../../assets/checked (2).svg";
import style from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { HOMEPAGE_ROUTE } from "src/core/routing";

export const PageReservarionOnlyResult = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = {
    button: {
      backgroundColor: "#E31340",
      textDecoration: "none",
      borderRadius: "25px",
      fontSize: "16px",
    },
  };

  const HomeNavigate = () => {
    navigate(HOMEPAGE_ROUTE);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <Box className={style.container}>
      <Box className={style.box}>
        <Box className={style.imageContainer}>
          <img src={mainPhoto} className={style.image} style={{ objectFit: "cover" }} />
        </Box>
        <Box className={style.overlayPic}></Box>
        <Box className={style.overlay}>
          <span className={style.overlayText1}>{t("sucessfull_payment")}</span>
          <span className={style.overlayText2}>{t("sucessfull_payment2")}</span>
          <img src={done} className={style.icon} />
          <Box className={style.additionalInfo}>
            <span className={style.additionalInfo1}>{t("additional_info")}</span>
            <span className={style.additionalInfo2}>{t("additional_info2")}</span>
          </Box>
          <Button style={styles.button} className={style.button} onClick={HomeNavigate}>
            {t("home_navigator")}
          </Button>
        </Box>
      </Box>
      <FooterPayment />
    </Box>
  );
};
