import { SlotDay } from "core/types/bundles";
import React, { SetStateAction } from "react";
import { Box, capitalize } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import style from "./styles.module.css";

export interface SimpleDialogProps {
  selectedValue: Date;
  fullSlots: SlotDay[] | undefined;
  onClick: (date: Date) => void;
  setSelectedDate: React.Dispatch<SetStateAction<Date>>;
}

export const CalendarDialog = ({
  selectedValue,
  fullSlots,
  onClick,
  setSelectedDate,
}: SimpleDialogProps) => {
  //middle version
  const [isMiddle, setIsMiddle] = React.useState(
    window.innerWidth < 1280 && window.innerWidth > 420,
  );
  const updateisMiddle = () => {
    setIsMiddle(window.innerWidth < 1280 && window.innerWidth > 420);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateisMiddle);
    return () => window.removeEventListener("resize", updateisMiddle);
  }, []);

  //mobile version
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 420);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 420);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const styles = {
    calendar: {
      "& .MuiPickersCalendarHeader-root": {
        position: "relative",
        paddingX: 0,
        marginBottom: "20px",
      },
      "& .MuiPickersCalendarHeader-labelContainer": {
        color: "#383838",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: 600,
      },
      "& .MuiPickersArrowSwitcher-root": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        "& button": {
          backgroundColor: "transparent",
          color: "#555555",
          padding: "5px",
          "&:disabled": {
            color: "#E1E1E1",
          },
        },
      },
      "& .MuiDayCalendar-slideTransition": {
        height: "100%",
        minHeight: "280px",
      },
      "& .MuiDayCalendar-header": {
        color: "#383838",
        gap: "1px",
        marginBottom: "20px",
        "& .MuiDayCalendar-weekDayLabel": {
          color: "#383838",
          backgroundColor: "#F6F6F6",
          margin: 0,
          width: "44px",
          height: "44px",
        },
      },
      "& .MuiDayCalendar-monthContainer": {
        "& button": {
          borderRadius: 0,
        },
        "& .MuiDayCalendar-weekContainer": {
          gap: "1px",
        },
        "& .MuiPickersDay-root": {
          width: isMobile ? "35px" : isMiddle ? "40px" : "44px",
          height: isMobile ? "35px" : isMiddle ? "40px" : "44px",
          margin: 0,
        },
      },
    },
  };

  const disableDate = (date: Dayjs) => {
    const dateString = date.format("YYYY-MM-DD");
    const foundSlot = fullSlots?.find((slot) => slot.date === dateString);
    return !foundSlot?.free_capacity;
  };
  return (
    <Box className={style.wrapper}>
      <Box className={style.dialog}>
        <DateCalendar
          dayOfWeekFormatter={(date) => capitalize(date)}
          value={selectedValue ? dayjs(selectedValue) : null}
          onChange={(value: Dayjs | null) => value && onClick(value.toDate())}
          disablePast={true}
          shouldDisableDate={(date: Dayjs) => disableDate(date)}
          views={["day"]}
          sx={styles.calendar}
          className={style.calendar}
          onMonthChange={(view) => setSelectedDate(view.toDate())}
        />
      </Box>
    </Box>
  );
};
