import React from "react";
import styles from "./styles.module.css";
import photo from "src/assets/img/global/side_page_cover_photo.jpg";
import { useTranslation } from "react-i18next";

export const ExpositionSmall = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <img src={photo} className={styles.image} alt="" />
      <div
        className={styles.header}
        dangerouslySetInnerHTML={{ __html: t("modal_exposition_one") }}
      />
      <div className={styles.line} />
      <div className={styles.content_block}>
        <span className={styles.text_extra_bold}>8 min</span>
        <span className={styles.text_default}>{t("modal_exposition_two")}</span>
      </div>
      <div className={styles.content_block}>
        <span className={styles.text_extra_bold}>10 min</span>
        <span className={styles.text_default}>{t("modal_exposition_three")}</span>
      </div>
    </div>
  );
};
