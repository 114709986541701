import { Box, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import linePoint from "../../../assets/list_item_red.svg";
import schoolIcon from "../../../assets/school_ikon.svg";
import churchInterior from "../../../assets/img/groups/colorful_interior.jpg";
import { ButtonFillOutForm } from "src/components/atoms/ButtonFillOutForm";
import { SecondarySection } from "src/components/molecules";
import { PhoneCallIcon } from "src/components/icons/PhoneCallIcon";

export const SchoolContentText: React.FC = () => {
  const { t } = useTranslation();
  const groupConditionTourAreas = [
    t("group_condition_tour_one"),
    t("group_condition_tour_two"),
    t("group_condition_tour_three"),
    t("group_condition_tour_four"),
  ];

  const rules = [
    t("school_condition_tower"),
    t("school_condition_toilets"),
    t("school_condition_safety"),
    t("school_condition_discount"),
  ];

  return (
    <Box className={styles.container}>
      <Box className={styles.mainBox}>
        <Box className={styles.titleBox}>
          <img alt="img" src={schoolIcon} style={{ margin: "0 20px 0 0" }} />
          <span className={styles.titleString}>{t("school_title")}</span>
        </Box>
        <Box className={styles.subtitleBox}>
          <span className={styles.subtitleText1}>{t("school_subtitle_text")}</span>
        </Box>
        <Box className={styles.noticeBox}>
          <div className={styles.line}></div>
          <Box className={styles.noticeWrapper}>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: t("school_condition_form") }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <Stack direction="row" gap={3}>
                <Stack direction="column">
                  <span className={styles.text}>{t("group_condition_tour_heading")}</span>
                  <Stack direction="column" sx={{ margin: "6px 0 18px 18px" }}>
                    {groupConditionTourAreas.map((area) => (
                      <SecondarySection
                        text={area}
                        key={area}
                        TextAsNode={<span className={styles.text}>{area}</span>}
                        transparentBg
                      />
                    ))}
                  </Stack>
                  <span className={styles.text}>{t("group_condition_tour_length")}</span>
                </Stack>
                <img
                  alt="church interior"
                  src={churchInterior}
                  className={styles.church_interior_img}
                />
              </Stack>
            </Box>
            {rules.map((text) => {
              return (
                <Box key={text} className={styles.notice}>
                  <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
                  <span className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
                </Box>
              );
            })}
          </Box>
          <div className={styles.line}></div>
        </Box>

        <Stack direction="column" gap={7} justifyContent="center" alignItems="center" pb={7}>
          <Box className={styles.buttonBox}>
            <ButtonFillOutForm />
          </Box>
          <span className={[styles.text, styles.capitalized_text].join(" ")}>{t("or")}</span>
          <a href={`tel:${t("phone")}`} style={{ textDecoration: "none" }}>
            <Stack direction="row" gap={3} justifyContent="center" alignItems="center">
              <PhoneCallIcon />
              <span className={styles.titleString}>{t("new_phone")}</span>
            </Stack>
          </a>
        </Stack>
      </Box>
    </Box>
  );
};
