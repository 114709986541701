import { Button } from "@mui/material";
import dotLoaderStyles from "src/components/molecules/ReservationComponent/styles.module.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import payUIcon from "src/assets/PAYU_LOGO_WHITE.png";

import { styles } from "./styles";

type TPaymentButtonProps = {
  isLoading: boolean;
  consentState: {
    [key in "consentOneState" | "consentTwoState" | "consentThreeState"]: boolean;
  };
  onSubmit: () => void;
  hasPayUIcon?: boolean;
};

export const PaymentButton = ({
  isLoading,
  consentState,
  onSubmit,
  hasPayUIcon = false,
}: TPaymentButtonProps) => {
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) {
      setIsButtonDisabled(true);
    } else {
      // Wait for 4 seconds after loading finishes before enabling the button
      const timeout = setTimeout(() => {
        setIsButtonDisabled(false);
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  const isDisabled =
    !consentState.consentOneState || !consentState.consentTwoState || isButtonDisabled;

  return (
    <Button
      disabled={isDisabled}
      sx={{
        ...styles.buttonToGo,
        "&:hover": {
          backgroundColor: "#E31340",
          color: "white",
        },
        "&:disabled": {
          backgroundColor: "grey",
          color: "white",
        },
      }}
      style={{ textDecoration: "none" }}
      onClick={onSubmit}
    >
      {isButtonDisabled ? (
        <>
          {t("redirect_in_progress")} <div className={dotLoaderStyles.loader}></div>
        </>
      ) : (
        <>
          {t("to_pay")}{" "}
          {hasPayUIcon && <img alt="payUIcon" src={payUIcon} style={{ marginLeft: "20px" }} />}
        </>
      )}
    </Button>
  );
};
