import styled from "styled-components";

interface ISelectButton {
  isAllowed: boolean;
  warning?: boolean;
}

export const Wrapper = styled.div<ISelectButton>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: ${(props) => (props.warning ? "1px solid #FFC107" : "1px solid #e1e1e1")};
  border-radius: 5px;
  gap: 10px;
  background: #ffffff;

  &:hover {
    border: ${(props) => `1px solid ${props.isAllowed ? "red" : "grey"}`};
  }
  @media (max-width: 350px) {
    padding: 8px;
    gap: 1px;
  }
`;

export const Cell = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #062762;
  gap: 8px;
  text-wrap: nowrap;
  margin-right: 10px;
`;

export const Time = styled.span`
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #062762;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SelectButton = styled.div<ISelectButton>`
  cursor: pointer;
  svg {
    font-size: 32px;
  }
`;

export const InnerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
