import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SuccessIcon from "src/components/icons/SuccessIcon";
import BadgesComponent from "src/components/molecules/BadgesComponent";
import mainPhoto from "../../../assets/payment_background.jpg";
import style from "./styles.module.css";

export const PageSuccessfulResult = () => {
  const { t } = useTranslation();

  return (
    <Box className={style.container}>
      <Box className={style.box}>
        <Box className={style.imageContainer}>
          <img src={mainPhoto} className={style.image} style={{ objectFit: "cover" }} />
        </Box>
        <Box className={style.overlayPic}></Box>
        <Box className={style.overlay}>
          <Box className={style.text_main}>
            <div>
              <span
                className={style.overlayText1}
                dangerouslySetInnerHTML={{ __html: t("payment_success") }}
              />
            </div>
          </Box>
          <Box className={style.icon_wrapper}>
            <SuccessIcon />
          </Box>
          <Box className={style.next_info} dangerouslySetInnerHTML={{ __html: t("next_info") }} />
          <div className={style.download_app}>{t("download_application")}</div>
          <span className={style.sub_descirption}>
            {t("have_tickets")} <br />
            {t("mobile_and_app")}
          </span>
          <BadgesComponent />
        </Box>
      </Box>
    </Box>
  );
};
