import React, { useEffect, useState } from "react";
import { Box, Link, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { LocSelect } from "src/components/atoms/LocSelect";
import { IconPrefixedContact } from "src/components/atoms/IconPrefixedContact";
import { JKTrustInfo } from "src/components/molecules/JKTrustInfo";
import { NeedHelp } from "src/components/molecules/NeedHelp";
import { DownloadApp } from "src/components/molecules/DownloadApp";
import { Legal } from "src/components/molecules/Legal";
import SecurityData from "src/components/atoms/SecurityData";
import ModalCondition from "src/components/atoms/ModalCondition";

import styles from "./styles.module.css";
import background from "../../../assets/img/footer/footer-bg.jpeg";
import logo from "../../../assets/logo_jakub_neg.svg";
import phoneIcon from "../../../assets/phone-call.svg";
import mailIcon from "../../../assets/mail.svg";
import facebookIconFooter from "../../../assets/facebook_white.svg";
import betrian_logo from "../../../assets/partnerIcons/RGB_SVG_betriangroup_positive 1.svg";

export const Footer = ({ truncated = false }: { truncated?: boolean }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const isFromApp = sessionStorage.getItem("isApp");

  const [isSmallerThanLg, setIsSmallerThanLg] = useState(window.innerWidth <= 1200);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsSmallerThanLg(window.innerWidth <= 1200);
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  if (isFromApp) {
    return null;
  }

  return (
    <Box className={styles.footerWrapper}>
      <Box className={styles.imageContainer}>
        <img
          src={background}
          alt="background"
          className={[styles.image, truncated && styles.imageReducedHeight].join(" ")}
          style={{ backgroundRepeat: "repeat-x" }}
        />
      </Box>
      <Box className={styles.overlayPic}></Box>
      <Stack direction="column" className={styles.footerContent}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          gap={{ xs: 3, lg: 0 }}
          justifyContent="space-between"
        >
          <Link href="#">
            <img
              src={logo}
              className={styles.logo}
              alt="logo"
              style={isSmallerThanLg ? { height: "60px" } : {}}
            />
          </Link>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            alignItems={{ xs: "flex-start", lg: "center" }}
            gap={{ xs: 2, lg: 6 }}
          >
            <IconPrefixedContact
              iconSrc={phoneIcon}
              text={t("phone")}
              linkHref={`tel:${t("phone")}`}
              className={styles.iconPrefixedLink}
            />
            <IconPrefixedContact
              iconSrc={mailIcon}
              text={t("email")}
              linkHref={`tel:${t("phone")}`}
              className={styles.iconPrefixedLink}
            />
          </Stack>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent={{ xs: "space-between", lg: "flex-end" }}
          >
            <LocSelect />
            <a target={"_blank"} rel={"noreferrer"} href="https://www.facebook.com/visitjakubbrno">
              <img src={facebookIconFooter} alt="facebook" style={{ marginRight: "20px" }} />
            </a>
          </Stack>
        </Stack>

        <Box className={styles.line}></Box>

        {!truncated && (
          <>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              gap={{ xs: 4, lg: 0 }}
              alignItems="start"
            >
              <JKTrustInfo />
              <NeedHelp />
              <DownloadApp />
            </Stack>

            <Box className={styles.line}></Box>
          </>
        )}
        {isMobile && (
          <div className={styles.technology_partner}>
            <span className={styles.technology_partner_text}>{t("tech_partner")}</span>
            <img className={styles.betrian_image} src={betrian_logo} alt="betrian_logo" />
          </div>
        )}
        <Legal setOpenModal={setOpenModal} />
      </Stack>
      <ModalCondition open={openModal} handleClose={() => setOpenModal(false)}>
        {({ handleClose }) => <SecurityData handleClose={handleClose} isModal={true} />}
      </ModalCondition>
    </Box>
  );
};
