import styled from "styled-components";

interface IButtonProps {
  isActive: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;

export const SubWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const Input = styled.input`
  flex-grow: 4;
  height: 40px;
  padding: 11px 13px;
  gap: 10px;
  border: 1px solid #e1e1e1;
  background: #ffffff;
  color: #000000;
`;

export const Button = styled.div<IButtonProps>`
  height: 50px;
  padding: 13px 19px;
  gap: 17px;
  border-radius: 25px;
  border: ${(props) => `1px solid ${props.isActive ? "red" : "gray"}`};
  color: ${(props) => (props.isActive ? "red" : "gray")};
  cursor: ${(props) => (props.isActive ? "pointer" : "default")};

  &:hover {
    color: #ffffff;
    background: ${(props) => (props.isActive ? "red" : "gray")};
  }
`;

export const MessageWrapper = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Message = styled.span`
  color: #e31340;
  font-size: 14px;
  font-weight: 400;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CrossIcon = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 28%;
  left: 90%;
`;
