import styled from "styled-components";

interface IGridItem {
  order: number;
}

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
    padding: 0 30px;
  }
`;

export const GridItem = styled.div<IGridItem>`
  width: 100%;
  max-height: 300px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  order: ${(props) => props.order || 0};
`;

export const GridItemText = styled.span`
  width: 100%;
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainImage = styled.img`
  max-width: 500px;
  object-fit: cover;

  @media (max-width: 768px) {
    max-height: 200px;
  }

  @media (max-width: 390px) {
    max-height: 175px;
  }
`;

export const Image = styled.img`
  max-height: 300px;
  max-width: 500px;
  object-fit: cover;

  @media (max-width: 768px) {
    max-height: 200px;
  }

  @media (max-width: 390px) {
    max-height: 175px;
  }
`;
