import { useTranslation } from "react-i18next";
import {
  GridItem,
  GridItemText,
  Image,
  ImageWrapper,
  MainImage,
  Wrapper,
} from "src/components/pages/PagePartners/components/GridInfoComponent/styledComponents";
import React, { useEffect, useState } from "react";
import { partners, partnersMob } from "src/core/lists/partners";

export const GridInfoComponent = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const gridItems = isMobile ? partnersMob : partners;

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Wrapper>
      {gridItems.map((item) => (
        <GridItem key={item.order} order={item.order}>
          {item.isMain ? (
            <ImageWrapper>
              <MainImage src={item.icon} />
            </ImageWrapper>
          ) : (
            <Image src={item.icon} alt="" />
          )}
          {item.text && <GridItemText dangerouslySetInnerHTML={{ __html: t(`${item.text}`) }} />}
        </GridItem>
      ))}
    </Wrapper>
  );
};
