import React from "react";
import { GiftCardPicker } from "src/components/molecules/GiftCardPicker";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PhoneCallIcon } from "src/components/icons/PhoneCallIcon";
import { IconPrefixedText } from "src/components/atoms/IconPrefixedText";
import { TimerIcon } from "src/components/icons/TimerIcon";
import { MouseCursorIcon } from "src/components/icons/MouseCursorIcon";
import { QrCodeIcon } from "src/components/icons/QrCodeIcon";
import { PresentIcon } from "src/components/icons/PresentIcon";
import { PhoneIcon } from "src/components/icons/PhoneIcon";
import { CalendarIcon } from "src/components/icons/CalendarIcon";

import styles from "./styles.module.css";

export const GiftCardsContent: React.FC = () => {
  const { t } = useTranslation();

  const howToUseTips = [
    {
      title: t("gift_cards_how_to_use_buy"),
      icon: <CalendarIcon />,
    },
    {
      title: t("gift_cards_how_to_use_qr"),
      icon: <QrCodeIcon />,
    },
    {
      title: t("gift_cards_how_to_use_guidelines"),
      icon: <MouseCursorIcon />,
    },
    {
      title: t("gift_cards_how_to_use_no_reservation"),
      icon: <PresentIcon />,
    },
    {
      title: t("gift_cards_how_to_use_mobile_app"),
      icon: <PhoneIcon />,
    },
  ];

  const giftCardRules = [
    t("gift_cards_condition_application"),
    t("gift_cards_condition_exchange"),
    t("gift_cards_condition_one_purchase"),
    t("gift_cards_condition_electronic_form"),
    t("gift_cards_condition_validity"),
  ];

  return (
    <Stack
      direction="column"
      gap={2}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      my={8}
      sx={{ color: "#000" }}
    >
      <Typography variant="body1" fontSize="26px" whiteSpace="pre-line" gutterBottom mx={2}>
        {t("vouchers_explanation")}
      </Typography>
      <Typography variant="body1" fontWeight="bold" fontSize="26px" mx={2}>
        {t("vouchers_repairs")}
      </Typography>
      <Box className={styles.container}>
        <Box className={styles.mainBox}>
          <Stack direction="column" gap={2}>
            <IconPrefixedText
              text={t("gift_cards_expiration")}
              sx={{ justifyContent: "center" }}
              textSx={{ fontSize: "22px" }}
              icon={<TimerIcon />}
            />
            <IconPrefixedText
              text={t("gift_cards_click")}
              sx={{ justifyContent: "center" }}
              textSx={{ fontSize: "22px" }}
              icon={<MouseCursorIcon />}
            />
          </Stack>

          <GiftCardPicker />

          <Box className={styles.line} />

          <Typography
            variant="h2"
            textAlign="center"
            mt={5}
            fontSize={{ xs: "20px", md: "26px" }}
            my={4}
          >
            {t("gift_cards_how_to_use")}
          </Typography>

          <Stack direction="column" gap={2} justifyContent="start" alignItems="start" pb={7}>
            {howToUseTips.map((tip, index) => {
              return (
                <Box key={tip.title} className={styles.notice}>
                  <IconPrefixedText
                    text={tip.title}
                    textSx={{ fontSize: "24px", textAlign: "left" }}
                    icon={tip.icon}
                    dangerouslySet={index === howToUseTips.length - 1}
                    sx={{ svg: { mt: 0.4 } }}
                  />
                </Box>
              );
            })}
          </Stack>

          <Stack direction="column" gap={2} justifyContent="center" alignItems="center" pb={7}>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{ a: { textDecoration: "none", color: "#E31340" } }}
              dangerouslySetInnerHTML={{ __html: t("gift_cards_contact") }}
            />
            <Typography variant="body1">{t("or")}</Typography>
            <a href={`tel:${t("phone")}`} style={{ textDecoration: "none" }}>
              <Stack direction="row" gap={3} justifyContent="center" alignItems="center">
                <PhoneCallIcon />
                <span className={styles.titleString}>{t("phone")}</span>
              </Stack>
            </a>
          </Stack>

          <Typography variant="h2" textAlign="center" my={3} fontSize={{ xs: "20px", md: "26px" }}>
            {t("gift_cards_conditions")}
          </Typography>

          <Stack
            direction="column"
            alignItems="start"
            pb={3}
            gap={0.5}
            textAlign="start"
            maxWidth="1100px"
            width="100%"
            mx="auto"
          >
            {giftCardRules.map((rule, index) => {
              return (
                <Stack key={rule} direction="row" gap={1} alignItems="start">
                  <Typography variant="body1" textAlign="start" fontSize={20}>
                    {index + 1}.&nbsp;
                  </Typography>
                  <Typography variant="body1" textAlign="start" fontSize={20}>
                    {rule}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};
