export const styles = {
  buttonToGo: {
    width: "343px",
    height: "50px",
    position: "relative",
    padding: "6px 12px",
    backgroundColor: "#E31340",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: "25px",
    "@media (max-width: 420px)": {
      width: "303px",
    },
  },
};
