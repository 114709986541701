import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Popover, Stack, Typography } from "@mui/material";

import { IndividualTourIcon } from "src/components/icons/IndividualTourIcon";
import { GuideTourIcon } from "src/components/icons/GuideTourIcon";
import { RibbonTicketIcon } from "src/components/icons/RibbonTicketIcon";
import { GIFT_CARDS_ROUTE, GUIDED_TOURS_ROUTE, TYPE_OF_CUSTOMERS } from "src/core/routing";

import TicketIcon from "../../../assets/tickets_white.svg";
import { style } from "src/components/molecules/Header/style";
import styles from "./styles.module.css";

export const ButtonPurchaseTickets = ({ isMobile = false }: { isMobile?: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleByYourselfClick = () => {
    navigate(TYPE_OF_CUSTOMERS);
    setAnchorEl(null);
  };

  const handleWithTourGuideClick = () => {
    navigate(GUIDED_TOURS_ROUTE);
    setTimeout(() => scroll({ top: 500, behavior: "smooth" }), 100);
    setAnchorEl(null);
  };

  const handleGiftCardsClick = () => {
    navigate(GIFT_CARDS_ROUTE);
    setTimeout(() => scroll({ top: 500, behavior: "smooth" }), 100);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "button-purchase-tickets-popover" : undefined;

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          ...style.primary,
          marginLeft: 0,
        }}
      >
        <Typography style={{ fontSize: "17px", cursor: "pointer", padding: "0 8px" }}>
          {t("want_to_buy_button")}
        </Typography>
        <img alt={"ticket"} src={TicketIcon} style={{ padding: "0 8px" }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          isMobile
            ? { vertical: "top", horizontal: "right" }
            : {
                vertical: "bottom",
                horizontal: "left",
              }
        }
        transformOrigin={isMobile ? { vertical: "bottom", horizontal: "right" } : undefined}
        className={styles.btn_purchase_tickets_popover}
        sx={isMobile ? { marginBottom: "6px" } : {}}
      >
        <Stack direction="column" sx={{ color: "#062762" }}>
          <Box onClick={handleByYourselfClick} className={styles.purchase_tickets_popover_item}>
            <Typography sx={{ p: 2 }}>{t("individual_tour")}</Typography>
            <IndividualTourIcon />
          </Box>
          <Box onClick={handleWithTourGuideClick} className={styles.purchase_tickets_popover_item}>
            <Typography sx={{ p: 2 }}>{t("guided_tours")}</Typography>
            <GuideTourIcon />
          </Box>
          <Box onClick={handleGiftCardsClick} className={styles.purchase_tickets_popover_item}>
            <Typography sx={{ p: 2 }}>{t("gift_cards")}</Typography>
            <RibbonTicketIcon />
          </Box>
        </Stack>
      </Popover>
    </>
  );
};
