import React from "react";
import {
  Wrapper,
  Content,
  Title,
  Description,
  SubDescriptionWrapper,
  SubDescription,
  ImageWrapper,
} from "src/components/molecules/AdvertisementDesktop/styledComponents";
import { HeadphonesIcon } from "src/components/icons/HeadphonesIcon";

import phones from "src/assets/Phones_mockup.png";
import BadgesComponent from "src/components/molecules/BadgesComponent";
import { useTranslation } from "react-i18next";

const AdvertisementDesktop = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={phones} alt="" />
      </ImageWrapper>
      <Content>
        <Title>{t("advertisement_heading")}</Title>
        <Description>{t("advertisement_body")}</Description>
        <SubDescriptionWrapper>
          <HeadphonesIcon />
          <SubDescription>{t("advertisement_headphones")}</SubDescription>
        </SubDescriptionWrapper>
        <BadgesComponent />
      </Content>
    </Wrapper>
  );
};

export default AdvertisementDesktop;
