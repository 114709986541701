import React, { useEffect, useState } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import FastTicketBuy from "src/components/atoms/FastTicketBuy";

import HomePicture from "src/components/atoms/HomePicture";
import HomePictureDesktop from "src/components/molecules/HomePictureDesktop";
import InfoComponent from "src/components/atoms/FAQ";
import MainMiddleContent from "src/components/atoms/MainMiddleContent";
import { MainMiddleContentDesktop } from "src/components/atoms/MainMiddleContentDesktop";
import Schedule from "src/components/molecules/Schedule";

import { Gallery } from "src/components/molecules";
import { GalleryDesktopMain, GalleryDesktopSub } from "src/components/molecules/GalleryDesktop";
import MainPageGalleryMobile from "src/components/atoms/MainPageGalleryMobile";
import { MiddlePanels } from "src/components/organisms";
import AdvertisementDesktop from "src/components/molecules/AdvertisementDesktop";
import MoneyCounter from "src/components/molecules/MoneyCounter";
import MainPageGalleryDesktop from "src/components/atoms/MainPageGalleryDesktop";
import AdvertisementMobile from "src/components/molecules/AdvertisementMobile";
import SectionTitleComponent from "src/components/atoms/SectionTitleComponent";
import { ModalSchedule } from "src/components/atoms/ModalSchedule/ModalSchedule";
import { PartnersRoute } from "src/components/atoms/PartnersRoute";

import styles from "./styles.module.css";

interface PageHomeProps {
  PurchaseTicketsClick: () => void;
  VisitClick: () => void;
  VisitRef: React.RefObject<HTMLDivElement>;
  VisitDesktopDesktop: React.RefObject<HTMLDivElement>;
}

export const PageHome = ({ PurchaseTicketsClick }: PageHomeProps) => {
  const [transportInfo, setTransportInfo] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1023);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onModalOpen = () => {
    setTransportInfo(true);
  };

  const onModalClose = () => {
    setTransportInfo(false);
  };

  return (
    <Box
      className={styles.wrapper}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {/*if you'd like a first visit banner (advertisement) at some point in the future, uncomment */}
      {/*<FirstVisitBanner /> */}
      {isDesktop ? (
        <MainMiddleContentDesktop>
          <HomePictureDesktop />
          <SectionTitleComponent />
          <MiddlePanels />
          <AdvertisementDesktop />
          <MoneyCounter />
          <PartnersRoute />
          <MainPageGalleryDesktop />
          <GalleryDesktopMain />
          <GalleryDesktopSub />
          <Schedule onVisible={onModalOpen} />
          <InfoComponent header="FAQ" mainText={t("faq_header")} navigateTo="faq" />
          <InfoComponent header={t("discover_church")} mainText={t("show_map")} navigateTo="map" />
          {transportInfo && <ModalSchedule onClose={onModalClose} />}
        </MainMiddleContentDesktop>
      ) : (
        <>
          <HomePicture purchaseTicketsClick={PurchaseTicketsClick} />
          <MainMiddleContent>
            <SectionTitleComponent />
            <MiddlePanels />
            <AdvertisementMobile />
            <MoneyCounter />
            <PartnersRoute />
            <MainPageGalleryMobile />
            <Gallery />
            <Schedule onVisible={onModalOpen} />
            <InfoComponent header="FAQ" mainText={t("faq_header")} navigateTo="faq" />
            <InfoComponent
              header={t("discover_church")}
              mainText={t("show_map")}
              navigateTo="map"
            />
            <FastTicketBuy />
            {transportInfo && <ModalSchedule onClose={onModalClose} />}
          </MainMiddleContent>
        </>
      )}
    </Box>
  );
};
