import history from "../../assets/CeilImage.png";
import persons from "../../assets/PersonChanged.png";
import photo1 from "../../assets/foto-pozadi2.png";
import photo4 from "src/assets/img/gallery/truss_blue_red.png";
import photo2 from "../../assets/img/gallery/aerial_church_view.jpg";
import photo3 from "src/assets/img/gallery/truss_blue_selfie.png";
import reconstructionPhoto from "src/assets/img/global/jacob_reconstruction.png";
export interface IGallery {
  id: string;
  header: string;
  image: string | string[];
  usual: boolean;
  href: string;
}
export const galleryDesktop: IGallery[] = [
  {
    id: "galery",
    header: "main_page_gallery",
    image: [photo1, photo4, photo3, photo2],
    usual: false,
    href: "/gallery",
  },
  {
    id: "persons",
    header: "main_page_persons",
    image: persons,
    usual: true,
    href: "/persons",
  },
];

export const galleryDesktopNext: IGallery[] = [
  {
    id: "history",
    header: "main_page_history",
    image: history,
    usual: true,
    href: "/history",
  },
  {
    id: "reconstruction",
    header: "main_page_restoration",
    image: reconstructionPhoto,
    usual: true,
    href: "/reconstruction",
  },
];
