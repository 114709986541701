import React, { useEffect } from "react";
import done from "../../../assets/checked.svg";
import mainPhoto from "../../../assets/img/global/truss_gold_blue.jpg";
import style from "./styles.module.css";
import { DownloadIcon } from "src/components/icons/DownloadIcon";
import { useTranslation } from "react-i18next";
export const PageSuccessfullResultMobile = () => {
  const reservationId = sessionStorage.getItem("current_reservation_id");
  const userEmail = sessionStorage.getItem("current_email");
  const href = `VisitJakubChurch://cz.VisitJakubBrno.officialApp/tickets?email=${userEmail}&ownership_code=${reservationId}`;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("isApp");
      localStorage.removeItem("isApp");
    };
  }, []);
  return (
    <div className={style.wrapper}>
      <div className={style.img_container}>
        <img src={mainPhoto} className={style.image} alt="" />
      </div>
      <div className={style.content}>
        <div className={style.description}>
          <span className={style.text} dangerouslySetInnerHTML={{ __html: t("payment_success") }} />
          <img src={done} className={style.image_done} alt="" />
        </div>
        <a href={href} className={style.button}>
          <span className={style.text}>{t("payment_download_end")}</span>
          <span className={style.text_description}>{t("payment_download")}</span>
          <DownloadIcon />
        </a>
        <div className={style.description_small}>
          <span className={style.text_small}>{t("have_tickets")}</span>
          <span className={style.text_small_bold}>{t("mobile_and_app")}</span>
        </div>
      </div>
    </div>
  );
};
