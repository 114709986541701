import React from "react";
import { useTranslation } from "react-i18next";
import {
  Description,
  HeadphonesTitle,
  HeadphonesWrapper,
  ImageWrapper,
  InsideWrapper,
  TextWrapper,
  Title,
  Wrapper,
} from "src/components/molecules/AdvertisementMobile/styledComponents";
import { HeadphonesIcon } from "src/components/icons/HeadphonesIcon";
import BadgesComponent from "src/components/molecules/BadgesComponent";

import phones from "../../../assets/Phones_mockup.png";

const AdvertisementMobile = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <InsideWrapper>
        <TextWrapper>
          <Title>{t("advertisement_heading")}</Title>
          <Description>{t("advertisement_body")}</Description>
          <HeadphonesWrapper>
            <HeadphonesIcon />
            <HeadphonesTitle>{t("advertisement_headphones_mobile")}</HeadphonesTitle>
          </HeadphonesWrapper>
          <BadgesComponent />
        </TextWrapper>
        <ImageWrapper>
          <img src={phones} alt="" />
        </ImageWrapper>
      </InsideWrapper>
    </Wrapper>
  );
};

export default AdvertisementMobile;
