import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  color: #e31340;

  @media (max-width: 768px) {
    font-family: Montserrat, serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`;

export const Button = styled.div`
  width: 100%;
  max-width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 19px;
  gap: 16px;
  border-radius: 25px;
  color: #ffff;
  cursor: pointer;
  background: #e31240;
`;
