import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Snackbar, SnackbarCloseReason, Stack, Typography } from "@mui/material";
import {
  TotalPrice,
  TotalPriceCash,
  TotalPriceItem,
} from "src/components/atoms/TicketsReceipt/styledComponents";
import {
  CheckBoxWrapper,
  Content,
  InfoContent,
  InfoSubContent,
  LogoWrapper,
  PayButton,
  PaymentContent,
  Wrapper,
} from "src/components/molecules/ReservationComponent/styledComponents";
import { GIFT_CARDS_ROUTE } from "src/core/routing";
import { RootState } from "src/core/redux/store";
import { Loc } from "src/core/types/loc";
import { PaymentButton } from "src/components";
import PersonComponent from "src/components/molecules/ReservationComponent/components/PersonComponent";
import ChangeComponent from "src/components/molecules/ReservationComponent/components/ChangeComponent";
import { CheckOutStep, TGiftCardPurchaseParams, Tab } from "src/core/types";
import { useGiftCardPurchase } from "src/core/queries/useGiftCardPurchase";
import { CheckboxComponent } from "src/components/atoms/Checkbox";
import { setStepToPen } from "src/core/utils";
import { useGiftCardPurchaseContext } from "src/core/context";
import { Logo } from "src/components/atoms/Logo";

import colorPayment from "src/assets/payment_color.svg";

import styles from "../ReservationComponent/styles.module.css";
import styless from "./styles.module.css";

interface ReservationProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  tabs: Tab[];
}

export const RecapPaymentGiftCards = ({
  updateCheckOutState,
  step,
  panels,
  tabs,
}: ReservationProps) => {
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const { t } = useTranslation();
  const reservationData = useSelector((state: RootState) => state.reservationData);
  const { giftCardCount, selectedGiftCard } = useGiftCardPurchaseContext();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [consentState, setConsentState] = useState<{
    [key in "consentOneState" | "consentTwoState" | "consentThreeState"]: boolean;
  }>({
    consentOneState: false,
    consentTwoState: false,
    consentThreeState: false,
  });

  const onConsentChange = (
    consentKey: "consentOneState" | "consentTwoState" | "consentThreeState",
    value: boolean,
  ) => {
    setConsentState((prev) => ({ ...prev, [consentKey]: value }));
  };

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const goToCurrentStep = (stepKey: number) => {
    const { newTabs, newPanels } = setStepToPen({
      tabs: tabs,
      panels: panels,
      currentKey: stepKey,
    });
    updateCheckOutState(newTabs, newPanels);
  };

  const { mutate, isLoading } = useGiftCardPurchase();

  const handleSubmit = () => {
    if (isLoading) return;
    if (!selectedGiftCard) {
      setOpen(true);
      return;
    }
    if (
      reservationData &&
      reservationData.userData &&
      consentState.consentOneState &&
      consentState.consentTwoState
    ) {
      const submitData: TGiftCardPurchaseParams = {
        gift_card_type: selectedGiftCard?.id ?? "",
        amount: giftCardCount,
        name: reservationData.userData.name,
        email: reservationData.userData.email,
        phone_number: Number(reservationData.userData.phone_number),
        send_promo: !consentState.consentThreeState,
      };
      mutate(submitData);
    }
  };

  return step.isEnabled ? (
    <Wrapper>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {t("error_gift_card_not_selected")}
        </Alert>
      </Snackbar>
      <Stack
        justifyContent="start"
        alignItems="center"
        gap="10px"
        width="100%"
        marginBottom="40px"
        direction="row"
      >
        <img src={colorPayment} alt="Payment" />
        <Typography variant="body1" color="#062762" fontWeight="bold">
          {t("recap_and_payment")}
        </Typography>
      </Stack>
      <Content>
        <InfoContent>
          <InfoSubContent>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Stack direction="column" p="24px" width="100%">
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems="top"
                mb={1}
                gap={2}
                width="100%"
              >
                <Typography
                  fontWeight="bold"
                  style={{ fontSize: "18px", color: "#062762", whiteSpace: "nowrap" }}
                >
                  {t("gift_card")}
                </Typography>
                <Stack direction="column" width={{ xs: "100%", sm: "65%" }} gap={{ xs: 0, sm: 2 }}>
                  <ChangeComponent
                    type={t("gift_cards_total")}
                    prevStep={() => navigate(GIFT_CARDS_ROUTE)}
                  />
                  <Typography
                    fontWeight="bold"
                    style={{ fontSize: "18px", color: "#062762", whiteSpace: "nowrap" }}
                  >
                    {giftCardCount}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </InfoSubContent>
          {reservationData && reservationData.userData && (
            <InfoSubContent>
              <PersonComponent
                person={reservationData.userData}
                prevStep={() => goToCurrentStep(2)}
              />
            </InfoSubContent>
          )}
        </InfoContent>
        <PaymentContent>
          <Stack direction={{ xs: "column-reverse", sm: "column" }} width="100%" spacing={2}>
            <Stack direction="column" width="100%" spacing={2}>
              <CheckBoxWrapper>
                <CheckboxComponent consentState={consentState} onConsentChange={onConsentChange} />
              </CheckBoxWrapper>
            </Stack>
            <Box className={styless.infoContainer} px="16px" py="24px" my={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" fontSize="16px">
                  {giftCardCount}x {t("card")} - {selectedGiftCard?.name[loc]}
                </Typography>
                <Typography variant="body1" fontSize="16px" color="#062762">
                  {selectedGiftCard?.monetary_value}
                </Typography>
              </Stack>
              <span className={styless.lineAlt} />
              <TotalPriceItem>
                <TotalPrice>{t("final_price")}</TotalPrice>
                <TotalPrice>
                  <TotalPriceCash>
                    {selectedGiftCard?.monetary_value
                      ? selectedGiftCard?.monetary_value * giftCardCount
                      : 0}{" "}
                    {t("cz_currency")}
                  </TotalPriceCash>
                </TotalPrice>
              </TotalPriceItem>
            </Box>
          </Stack>
          <PaymentButton
            consentState={consentState}
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
        </PaymentContent>
      </Content>
    </Wrapper>
  ) : null;
};
