import { Box, Typography } from "@mui/material";
import logo from "../../../assets/logo_jakub_neg.svg";
import { useTranslation } from "react-i18next";
import phoneIcon from "../../../assets/phone-call.svg";
import mailIcon from "../../../assets/mail.svg";
import facebookIcon from "../../../assets/facebook_white.svg";
import instaIcon from "../../../assets/instagram_white.svg";
import { ModalComponent } from "src/components/atoms/ModalConditions";
import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import desktopPhoto from "../../../assets/img/global/truss_gold_blue.jpg";
import mobilePhoto from "../../../assets/img/footer/footer-bg.jpeg";

export const FooterPayment = () => {
  const isFromApp = sessionStorage.getItem("isApp");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  const footerPhoto = isMobile ? mobilePhoto : desktopPhoto;

  if (isFromApp) {
    return null;
  }

  return (
    <Box className={styles.box}>
      <Box className={styles.imageContainer}>
        <img src={footerPhoto} className={styles.image} />
      </Box>
      <Box className={styles.overlayPic}></Box>

      <Box className={styles.overlay}>
        <Box className={styles.boxHeader}>
          <div className={styles.boxHeaderLogo}>
            <img src={logo} className={styles.logo} />
            <div className={styles.socialIconsMobile}>
              <img src={facebookIcon} style={{ marginRight: "20px" }} />
              <img src={instaIcon} />
            </div>
          </div>
          <div className={styles.phoneEmail}>
            <div className={styles.icons} style={{ marginRight: "50px" }}>
              <img src={phoneIcon} style={{ marginRight: "15px" }} />
              <Typography variant="h2">{t("phone")}</Typography>
            </div>
            <div className={styles.icons} style={{ marginRight: "50px" }}>
              <img src={mailIcon} style={{ marginRight: "15px" }} />
              <Typography variant="h2"> {t("email")}</Typography>
            </div>
            <div className={styles.socialIconsDesktop}>
              <img src={facebookIcon} style={{ marginRight: "20px" }} />
              <img src={instaIcon} />
            </div>
          </div>
        </Box>
        <Box className={styles.boxBody}>
          <Box className={styles.gridItem}>
            <div className={styles.text}>
              <Typography>{[t("footer_description1")]}</Typography>
            </div>
            <div className={styles.text}>
              <Typography>{[t("footer_description2")]}</Typography>
            </div>
          </Box>
        </Box>
        <Box className={styles.boxFooter}>
          <Box className={styles.gridItem}>
            <Box className={styles.rights}>
              <div className={styles.rightsFirst}>
                <Typography
                  color="inherit"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => setOpenModal(true)}
                >
                  {[t("cookies")]}
                </Typography>
                <ModalComponent
                  text={t("terms_and_conditions_text")}
                  open={openModal}
                  handleClose={() => setOpenModal(false)}
                />
              </div>
              <div className={styles.rightsSecond}>
                <Typography
                  color="inherit"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => setOpenModal(true)}
                >
                  {[t("personal_info")]}
                </Typography>
                <ModalComponent
                  text={t("terms_and_conditions_text")}
                  open={openModal}
                  handleClose={() => setOpenModal(false)}
                />
              </div>
              <Typography
                color="inherit"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setOpenModal(true)}
              >
                {[t("conditions")]}
              </Typography>
              <ModalComponent
                text={t("terms_and_conditions_text")}
                open={openModal}
                handleClose={() => setOpenModal(false)}
              />
            </Box>
            <Typography style={{ whiteSpace: "nowrap" }} variant="body2">
              {[t("rights_reserved")]}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
