import React, { forwardRef, SetStateAction, useEffect, useState } from "react";
import { Panel, RecapPaymentGiftCards } from "src/components";
import { PersonalData } from "src/components/molecules/PersonalData";

import { CheckOutStep, Tab } from "core/types";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import colorTicket from "src/assets/Activeticket.svg";
import grayTicket from "src/assets/BasicTicket.svg";
import colorPersonal from "src/assets/kontaktni_udaje_cervene.svg";

import grayPersonal from "src/assets/kontaktni_udaje_svetle_sede.svg";
import bluePersonal from "src/assets/kontaktni_udaje_sede.svg";
import colorPayment from "src/assets/payment_color.svg";

import grayPayment from "src/assets/payment_gray.svg";
import bluePayment from "src/assets/payment_blue.svg";

import style_css from "./styles.module.css";

export interface ICheckOutState {
  tabs: Tab[];
  panels: CheckOutStep[];
}

interface ICheckOutProps {
  setTimer: (value: boolean) => void;
  setCurrentTab?: React.Dispatch<SetStateAction<string | null | number>>;
}

const GiftCardPurchasePhase = forwardRef<HTMLDivElement, ICheckOutProps>(
  ({ setTimer, setCurrentTab }, ref) => {
    const [activeTab] = useState<string>("data_time_chosen");
    const [nextStep, setNextStep] = useState<string>("");
    const [shouldBePensShown, setShouldBePensShown] = useState<boolean>(true);

    const isApp = sessionStorage.getItem("isApp");
    const { t } = useTranslation();

    const [checkOutState, setCheckOutState] = useState<ICheckOutState>({
      tabs: [
        {
          title: t("select_ticket"),
          icon: grayTicket,
          activeIcon: colorTicket,
          doneIcon: grayTicket,
          isTabActive: false,
          isTabDone: true,
          key: "1",
        },
        {
          title: t("contact_information"),
          icon: grayPersonal,
          activeIcon: colorPersonal,
          doneIcon: bluePersonal,
          isTabActive: true,
          isTabDone: false,
          key: "2",
        },
        {
          title: t("recap_and_payment"),
          icon: grayPayment,
          activeIcon: colorPayment,
          doneIcon: bluePayment,
          isTabActive: false,
          isTabDone: false,
          key: "3",
        },
      ],
      panels: [
        {
          description: t("contact_information"),
          isEnabled: true,
          key: "2",
          component: PersonalData,
          isStepFinished: false,
          isPen: false,
        },
        {
          description: t("recap_and_payment"),
          isEnabled: false,
          key: "3",
          component: RecapPaymentGiftCards,
          isStepFinished: false,
          isPen: false,
        },
      ],
    });

    const updateCheckOutState = (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => {
      setCheckOutState((prevState) => {
        return {
          ...prevState,
          tabs: newTabsState,
          panels: newPanelsState,
        };
      });
    };

    const updateIsPaymentEnabled = (): boolean => {
      const enabledPanelKey = checkOutState.panels.find((panel) => panel.isEnabled);
      return enabledPanelKey?.key !== "4";
    };

    useEffect(() => {
      setShouldBePensShown(updateIsPaymentEnabled());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkOutState]);

    useEffect(() => {
      const currentStep = checkOutState.panels.find((panel) => panel.isEnabled);
      const index = currentStep && checkOutState.panels.indexOf(currentStep);
      if (index && index < checkOutState.panels.length) {
        const next = index + 1;
        if (checkOutState.panels[next]) {
          setNextStep(checkOutState.panels[next].description);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkOutState.panels, checkOutState.tabs]);

    useEffect(() => {
      if (setCurrentTab) {
        const foundTab = checkOutState.tabs.find((el: Tab) => el.isTabActive);
        setCurrentTab(foundTab?.key || "-1");
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkOutState.panels]);

    return (
      <Box className={style_css.box} sx={isApp ? { marginTop: "140px" } : {}} ref={ref}>
        <Box className={style_css.wrapper} ref={ref}>
          <Panel tabs={checkOutState?.tabs} style={{ marginTop: "10px" }} />
        </Box>
        {checkOutState.panels.map((step) => {
          const Component = step.component;
          return (
            <Component
              shouldBePensShown={shouldBePensShown}
              setTimer={setTimer}
              key={step.key}
              step={step}
              tabs={checkOutState.tabs}
              activeTab={activeTab}
              panels={checkOutState.panels}
              updateCheckOutState={updateCheckOutState}
              checkOutState={checkOutState}
              nextStep={nextStep}
            />
          );
        })}
      </Box>
    );
  },
);

GiftCardPurchasePhase.displayName = "GiftCardPurchasePhase";
export default GiftCardPurchasePhase;
