import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ChevronDownIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{ position: "absolute", height: "5rem" }}>
    <svg viewBox="0 0 68 33" className="chevron-down">
      <path d="M.58 3.414l27.432 27.433c2.715 2.715 7.167 2.787 9.964.164L67.356 3.46 64.62.54 35.24 28.093c-1.222 1.146-3.212 1.114-4.4-.075L3.408.586.579 3.414z"></path>
    </svg>
    <style>
      {`
      .chevron-down {
        fill: #E31240;
        margin: auto;
        width: 1em;
        height: 1em;
        animation: hover-me .5s infinite ease-in-out alternate; 
      }
    
      @keyframes hover-me {
        100% { transform: translate3d(0, 7px, 0); }
      }
    `}
    </style>
  </SvgIcon>
);
