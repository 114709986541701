import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

import { CountdownTimer } from "src/components";
import GiftCardPurchasePhase from "src/components/organisms/GiftCardPurchasePhase";

export const PageGiftCardPurchase = () => {
  const [, setTimer] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string | null | number>(null);

  //queries
  const PurchaseRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (PurchaseRef && PurchaseRef?.current) {
      window.scrollTo({
        top: PurchaseRef?.current.offsetTop - 200,
        behavior: "smooth",
      });
    }
  }, [currentTab]);

  return (
    <Box sx={{ minHeight: "calc(100dvh - 500px)" }}>
      {currentTab !== "1" && <CountdownTimer />}
      <GiftCardPurchasePhase setTimer={setTimer} ref={PurchaseRef} setCurrentTab={setCurrentTab} />
    </Box>
  );
};
