import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 769px) {
    padding: 0 30px;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
  color: #062762;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 22px;
  }
`;

export const SubTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.82px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Button = styled.div`
  width: 100%;
  max-width: 320px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 19px;
  gap: 17px;
  border-radius: 25px;
  background: #e31240;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin-top: 30px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const TitleText = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;

  @media (max-width: 768px) {
    text-align: center;
  }
`;
