import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100vw;
  max-width: 1360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px auto 0 auto;
  padding: 46px 131px;
  background: #f8f8f8;

  @media (max-width: 768px) {
    width: 90vw;
    padding: 0;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

export const SubTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.82px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px dotted #b9b9b9;
  margin: 40px 0;
`;
