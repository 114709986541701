import euIcon from "../../assets/partnerIcons/EUIcon.svg";
import brnoIcon from "../../assets/partnerIcons/Logo_Brno 1.svg";
import morav from "../../assets/partnerIcons/morava.svg";
import morav_BD from "../../assets/partnerIcons/logo BD 1.svg";

export const institutionsList: { icon: string }[] = [
  {
    icon: euIcon,
  },
  {
    icon: brnoIcon,
  },
  {
    icon: morav,
  },
  {
    icon: morav_BD,
  },
];
