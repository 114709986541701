import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Content,
  HorizontalLine,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/pages/PagePartners/styledComponents";
import { GridInfoComponent } from "src/components/pages/PagePartners/components/GridInfoComponent";
import { InstitutionsComponent } from "src/components/pages/PagePartners/components/InstitutionsComponent";
import { DonorsComponent } from "src/components/pages/PagePartners/components/DonorsComponent";
import { ContentPicture } from "src/components";

import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-17.jpg";

export const PagePartners = () => {
  const { t } = useTranslation();

  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop - 5500,
        behavior: "smooth",
      });
    }
  }, [topRef]);
  return (
    <>
      <ContentPicture
        title={"our_partners"}
        buttonText={""}
        subtitle={""}
        mainPhoto={mainPhoto}
        hasButton={false}
      />
      <Wrapper ref={topRef}>
        <Content>
          <TitleWrapper>
            <Title> {t("partners")}</Title>
            <SubTitle>{t("partners_description")}</SubTitle>
          </TitleWrapper>
          <GridInfoComponent />
          <HorizontalLine />
          <InstitutionsComponent />
          <HorizontalLine />
          <DonorsComponent />
        </Content>
      </Wrapper>
    </>
  );
};
