import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/atoms/PartnersRoute/styledComponents";

import donorIcon from "../../../assets/partnerIcons/Frame 696.svg";
import arrow from "../../../assets/arrow_white.svg";

export const PartnersRoute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <TitleWrapper>
        <img src={donorIcon} alt="donorIcon" />
        <Title>{t("who_is_donor")}</Title>
      </TitleWrapper>
      <Button onClick={() => navigate("/partners")}>
        {t("see_more")} <img src={arrow} alt="arrow" />
      </Button>
    </Wrapper>
  );
};
