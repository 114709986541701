import blueChurchWalkway from "../../assets/img/gallery/blue_church_walkway.jpg";
import brightChurchAltar from "../../assets/img/gallery/bright_church_altar.jpg";
import redAtticPathway from "../../assets/img/gallery/red_attic_pathway.jpg";
import churchInteriorRedBlueLights from "../../assets/img/global/church_interior_red_blue_lights.jpg";
import gothicVaultedCeiling from "../../assets/img/gallery/gothic_vaulted_ceiling.jpg";
import churchLongAisle from "../../assets/img/gallery/church_long_aisle.jpg";
import vaultedCeilingWithWindows from "../../assets/img/gallery/vaulted_ceiling_with_windows.jpg";
import cathedralSpiralStaircase from "../../assets/img/gallery/cathedral_spiral_staircase.jpg";
import grandChurchAltar from "../../assets/img/gallery/grand_church_altar.jpg";
import aerialCathedralView from "src/assets/img/gallery/aerial_church_view.jpg";
import churchSideExterior from "../../assets/img/gallery/church_side_exterior.jpg";
import symmetricalRedAtticBeams from "../../assets/img/gallery/symmetrical_red_attic_beams.jpg";
import blueLitAtticStructure from "../../assets/img/gallery/blue_lit_attic_structure.jpg";
import complexRedAtticBeams from "../../assets/img/gallery/complex_red_attic_beams.jpg";
import purpleLitAtticWalkway from "../../assets/img/gallery/purple_lit_attic_walkway.jpg";

export const galleryPhotos = [
  blueChurchWalkway,
  brightChurchAltar,
  redAtticPathway,
  churchInteriorRedBlueLights,
  gothicVaultedCeiling,
  churchLongAisle,
  vaultedCeilingWithWindows,
  cathedralSpiralStaircase,
  grandChurchAltar,
  aerialCathedralView,
  churchSideExterior,
  symmetricalRedAtticBeams,
  blueLitAtticStructure,
  complexRedAtticBeams,
  purpleLitAtticWalkway,
];
