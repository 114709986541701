import { IBundle } from "core/types/bundles";

export const setSelectedBundle = (selectedBundle: IBundle) => ({
  type: "SET_BUNDLE",
  payload: selectedBundle,
});

export const setBundleDiscount = (discount: number) => ({
  type: "SET_DISCOUNT",
  payload: discount,
});

export const resetBundleDiscount = () => ({
  type: "RESET_DISCOUNT",
});
