import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0 30px;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const SubTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.82px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const IconsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 60px;
`;
