import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ContentWrapper,
  SubTitle,
  TextWrapper,
  Title,
  TitleText,
  TitleWrapper,
  Wrapper,
} from "src/components/pages/PagePartners/components/DonorsComponent/styledComponents";

import ticketIcon from "../../../../../assets/tickets_white.svg";
import column from "../../../../../assets/prvek-chrlic 1.png";

export const DonorsComponent = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t("donors")}</Title>
        <SubTitle>{t("donors_desc")}</SubTitle>
        <a
          href="https://www.jakubovaokna.cz/web/donor_table"
          style={{ textDecoration: "none" }}
          target={"_blank"}
          rel="noreferrer"
        >
          <Button>
            {t("donors_list")}

            <img src={ticketIcon} alt="ticketIcon" />
          </Button>
        </a>
      </TitleWrapper>
      <ContentWrapper>
        <TextWrapper>
          <TitleText>{t("want_to_update")}</TitleText>
          <SubTitle>{t("update")}</SubTitle>
          <a
            href="https://www.jakubovaokna.cz/"
            style={{ textDecoration: "none" }}
            target={"_blank"}
            rel="noreferrer"
          >
            <Button>
              {t("click_update")}

              <img src={ticketIcon} alt="ticketIcon" />
            </Button>
          </a>
        </TextWrapper>
        <img src={column} alt="column" />
      </ContentWrapper>
    </Wrapper>
  );
};
