import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  margin-bottom: 100px;
`;

export const InsideWrapper = styled.div<{ isApp: boolean }>`
  width: 100vw;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ isApp }) => (isApp ? "40px auto 150px auto" : "130px auto 150px auto")};
  gap: 30px;

  @media (max-width: 1900px) {
    margin: ${({ isApp }) => (isApp ? "20px 0 0 0" : "130px 0 0 0")};
    max-width: 100vw;
    padding: 0 0 0 60px;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
