import React, { useEffect, useState } from "react";
import { Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { styles } from "./styles";
import styless from "./styles.module.css";

import betrian_logo from "../../../assets/partnerIcons/RGB_SVG_betriangroup_positive 1.svg";

export const Legal = ({ setOpenModal }: { setOpenModal: (open: boolean) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <Stack
      direction={{ xs: "column-reverse", lg: "row" }}
      gap={{ xs: 3, lg: 0 }}
      justifyContent="space-between"
    >
      {!isMobile && (
        <div className={styless.technology_partner}>
          <span className={styless.technology_partner_text}>{t("tech_partner")}</span>
          <img className={styless.image} src={betrian_logo} alt="betrian_logo" />
        </div>
      )}
      <Typography variant="body2">{t("rights_reserved")}</Typography>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        gap={{ xs: 1, lg: 5 }}
        justifyContent={{ xs: "flex-start", lg: "flex-end" }}
        sx={styles.linksWrapper}
      >
        <Typography variant="body2">
          <Link
            color="inherit"
            target="_blank"
            sx={{ marginRight: "5px" }}
            href="https://docs.google.com/document/d/1LnHoNo55yx8gXgawdA9cq-gku8rHzbbpeEiye-0WbQc/edit#heading=h.4iw50apfwhgj"
          >
            {t("operating_rules")}
          </Link>
        </Typography>
        <Typography variant="body2" onClick={() => setOpenModal(true)}>
          {[t("cookies")]}
        </Typography>
        <Typography variant="body2" onClick={() => navigate("/privacy-policy")}>
          {[t("personal_info")]}
        </Typography>

        <Typography variant="body2">
          <Link
            href="https://docs.google.com/document/d/1nAL-uK1yiYK_QTyyxRDXfOo6ZrevjXBPLOUYQ6SEiw8/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
            color="inherit"
          >
            {[t("conditions")]}
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
};
